html,body,#root,#chat-page,.result-page{

    position: relative;
    width: 100%;
    height: 100%;
    background-color: #eeeeee;
    color: #000;
    font-size: 14px;
}

.result-page{
    background-color: #fff;
}

#chat-page{
    max-width: 640px;
    margin: 0 auto;
    background-color: #ffffff;
    --adm-color-weak:#222;

}

.loading{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.chat-order,.chat-log{
    /* padding: 10px; */
    background-color: #eee;
}
.chat-log{
    background-color: #fff;
}
.chat-footer{
    position: fixed;
    bottom: 0px;
    min-height: 60px;
    background-color: #fff;
    border-top: 1px solid #ddd;
    width: 100%;
    max-width: 640px;
}
.navbar{
    border-bottom: 1px solid #ddd;
}
.chat-footer-l{
    padding: 10px;
    display: flex;
    align-items: center;
    position: relative;
}

.copy-btn{
    margin-left: 10px;

}

.send-text{
    /* margin-left: auto; */
    /* 
     */
    /* padding-right: 100px; */
    /* width: 100%;  */
    width: 100%;
    
}
.btn-send{
    /* margin-left: auto; */

    width: 60px;

    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.chat-container {
    /* width: 400px; */
    max-width: 100%;
    background-color: #fff;
    /* border-radius: 8px; */
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.chat-log{
    padding-bottom: 70px;
}

.chat-box {
    flex: 1;
    padding: 16px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.message {
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
}

.user-message .message-content {
    background-color: #dcf8c6;
    align-self: flex-end;
    border-radius: 15px 15px 0 15px;
}

.friend-message .message-content {
    background-color: #ebebeb;
    align-self: flex-start;
    border-radius: 15px 15px 15px 0;
}

.message-content {
    max-width: 60%;
    padding: 10px;
    word-wrap: break-word;
}

.message-time {
    font-size: 0.75em;
    color: #888;
    margin-top: 4px;
    align-self: flex-end;
}

.chat-input {
    display: flex;
    border-top: 1px solid #ddd;
    padding: 10px;
}

.chat-input input {
    flex: 1;
    border: 1px solid #ddd;
    border-radius: 20px;
    padding: 10px;
    font-size: 1em;
    margin-right: 10px;
    outline: none;
}

.chat-input button {
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 1em;
    outline: none;
}

.chat-input button:hover {
    background-color: #45a049;
}